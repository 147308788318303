<template>
  <div class="space-y-4">
    <asom-modal
      title="Update Ticket Log (Pre-encoded Ticket)"
      v-model="showModal"
      :dismissible="false"
    >
      <p>
        This will update the ticket log to {{ formData.issueTickets }} tickets
        for the transaction {{ transactionNo }}
      </p>
      <p class="pt-4">Are you sure you would like to proceed?</p>
      <div class="flex flex-row-reverse pt-4">
        <div class="pl-4">
          <asom-button
            @click="onSubmit"
            :disabled="isSubmitting"
            :loading="isSubmitting"
            text="OK"
          />
        </div>
        <div>
          <asom-button
            @click="onCloseModal"
            text="Cancel"
            variant="secondary"
          />
        </div>
      </div>
    </asom-modal>
    <asom-card>
      <form class="grid sm:grid-cols-1 md:grid-cols-2 gap-4">
        <asom-alert
          class="col-span-2"
          v-if="error"
          variant="error"
          :error-message="error"
        />
        <div class="pb-5">
          <legend class="text-base font-medium text-gray-900">
            Transaction No
          </legend>
          <p class="text-sm text-gray-500">
            {{ transactionNo }}
          </p>
        </div>
        <div />
        <asom-form-field
          label="Number of Tickets"
          required
          :state="inputStates('formData.issueTickets')"
          error="Issue Tickets required"
        >
          <asom-input-text
            type="number"
            max="24"
            min="0"
            v-model="formData.issueTickets"
            :state="inputStates('formData.issueTickets')"
          />
        </asom-form-field>
        <div />
        <asom-form-field
          required
          label="Remarks"
          :state="inputStates('formData.remarks')"
        >
          <asom-input-textarea
            type="text"
            v-model="formData.remarks"
            :state="inputStates('formData.remarks')"
            :maxlength="1000"
          />
        </asom-form-field>
        <div class="col-span-2 flex justify-end space-x-4">
          <asom-button
            text="Cancel"
            variant="secondary"
            @click="$router.push({ name: 'Pre Encoded Ticket Main Page' })"
          />
          <asom-button
            text="Submit"
            @click="submitClicked"
            :loading="isSubmitting"
          />
        </div>
      </form>
    </asom-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import get from "lodash.get";
import { required } from "@vuelidate/validators";
import inputStates from "@/mixins/inputStates";
import { useVuelidate } from "@vuelidate/core";
import { editTicketLog, getTicketLogDetails } from "../../../../services/cashManagement.service";

export default {
  name: "UpdateTicket",
  mixins: [inputStates],
  setup: () => ({ v$: useVuelidate() }),
  data() {
    return {
      transactionNo: "",
      events: [],
      formData: {
        issueTickets: 0,
        remarks: null,
      },
      isLoading: false,
      isSubmitting: false,
      error: null,
      showModal: false,
    };
  },
  validations() {
    return {
      formData: {
        issueTickets: { required },
        remarks: { required },
      },
    };
  },
  mounted() {
    this.getPageData();
  },
  computed: {
    ...mapGetters({
      rosterPlanDate: "currentRoster/currentRosterPlanDate",
      rosterDws: "currentRoster/currentRosterShift",
    }),
  },
  methods: {
    async getPageData() {
      const resp = await getTicketLogDetails({
        id: get(this.$route, 'params.id')
      });
      if (resp.success) {
        this.transactionNo = get(resp.payload.data, "transactionNo", "");
        this.formData.issueTickets = get(resp.payload.data, "noOfTickets", 0);
      }else {
        this.error = resp.payload;
        this.$scrollTop();
        return;
      }
    },
    submitClicked() {
      if (this.isSubmitting) return;
      this.error = "";
      this.v$.$reset();
      this.v$.$touch();
      if (!this.v$.$invalid) {
        this.showModal = true;
      } else {
        this.error = "Please complete all required fields";
        this.$scrollTop();
      }
    },
    async onSubmit() {
      this.isSubmitting = true;
      const result = await editTicketLog({
        id: get(this.$route, 'params.id'),
        noOfTickets: this.formData.issueTickets,
        remarks: this.formData.remarks,
        isVoided: false,
        rosterPlanDate: this.rosterPlanDate,
        rosterDWS: this.rosterDws,
      });
      this.isSubmitting = false;
      this.showModal = false;
      if (result.success) {
        this.$router.push({ name: "Pre Encoded Ticket Main Page" });
      } else {
        this.error = result.payload;
        this.$scrollTop();
      }
    },
    onCloseModal() {
      this.showModal = false;
    },
  },
};
</script>